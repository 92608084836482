import React, { useState, useEffect } from "react";

import UserRoleForm from "../../components/UserRoleForm";
import Autocomplete from "../../components/Form/StyledAutocomplete";
import { BasePaper, Row, ErrorMessage } from "../../components/Base";

import { getCrews, setUserRoles } from "../../api/crew";
import { getUserRoles } from "../../api/user";
import { getRoles } from "../../api/roles";

import UserRolesEditService from "./service";

import "./index.scss";

const UserRolesEdit = ({ match }) => {
  const { userId } = match.params;
  const [crews, setCrews] = useState(null);
  const [userCrewRoles, setUserCrewRoles] = useState(null);
  const [selectedCrew, setSelectedCrew] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    (async () => {
      const allCrews = await getCrews();
      const userRoles = await getUserRoles(userId);
      const roles = await getRoles();

      setCrews(UserRolesEditService.mapCrews(allCrews));
      setAvailableRoles(UserRolesEditService.mapRoles(roles));
      setUserCrewRoles(userRoles);
    })();
  }, [userId, refresh]);

  const onCrewChange = (crew) => {
    setSelectedCrew(crew);
    setError(null);
    const roles = userCrewRoles.find((r) => r.id === crew.id)?.roles || [];
    setSelectedRoles(roles);
  };

  const save = async (selectedRoles) => {
    if (!selectedCrew.id) {
      setError("Please select company");
    }

    const response = await setUserRoles(selectedCrew.id, userId, selectedRoles);
    console.log("response", response);
    setRefresh(refresh + 1);
    return response?.ok;
  };

  const rowFormatter = (innerEl) => (
    <Row display={"flex"} mb={0} justifyContent={"space-between"}>
      <Row width={"50%"} mr={1}>
        <Autocomplete
          items={crews}
          selected={selectedCrew}
          label={"Crew"}
          onChange={onCrewChange}
          // formatLabel={(item) => `(${item.id}) ${item.name}`}
        />
      </Row>
      <Row width={"50%"}>{innerEl}</Row>
    </Row>
  );

  const cancel = () => {
    onCrewChange(null);
  };

  return (
    <>
      {crews ? (
        <>
          <BasePaper>
            <UserRoleForm
              rowFormat={rowFormatter}
              selected={selectedRoles}
              available={availableRoles}
              onCancel={cancel}
              onSave={save}
            />
            <ErrorMessage error={error} />
          </BasePaper>
          <BasePaper className="roles">
            <h5>Assigned Roles</h5>
            {userCrewRoles?.map((crew) => (
              <div className="crew">
                <h6>{crew.name}</h6>
                <ul>
                  {(crew.roles || []).map((r) => (
                    <li>{r.name}</li>
                  ))}
                </ul>
              </div>
            )) || <span>None</span>}
          </BasePaper>
        </>
      ) : null}
    </>
  );
};

export default UserRolesEdit;
