import { useCallback, useContext, useState } from "react";

import MuiAutocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import FormContext from "./FormContext";
import { SelectableObject } from "./types";

interface BaseAutocompleteProps {
  label: string;
  items: SelectableObject[];
  selected?: SelectableObject | SelectableObject[] | null;
  required?: boolean;
  onChange: (value: SelectableObject | SelectableObject[] | null) => void;
  onBlur?: (e: any) => void;
  multiple: boolean;
  formatLabel?: (item: any) => string;
  error?: string;
  sx?: Record<string, any>;
}

const BaseAutocomplete = ({
  label,
  items,
  selected,
  required,
  onChange,
  multiple,
  formatLabel,
  error,
  ...rest
}: BaseAutocompleteProps) => {
  return (
    <MuiAutocomplete
      size="small"
      options={items || []}
      onChange={(_, v) => onChange(v)}
      value={selected || (multiple ? [] : null)}
      getOptionLabel={
        formatLabel ||
        ((item) => (item?.getDisplayName ? item.getDisplayName() : ""))
      }
      multiple={multiple}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          error={!!error}
        />
      )}
      {...rest}
    />
  );
};

export interface AutocompleteProps {
  label: string;
  items: SelectableObject[];
  selected?: SelectableObject | SelectableObject[] | null;
  required?: boolean;
  onChange: (value: any) => void;
  multiple?: boolean;
  // formatLabel is a legacy property for backward compatibility, use getDisplayName in new code instead
  formatLabel?: (item: any) => string;
  sx?: Record<string, any>;
}

export const Autocomplete = ({
  label,
  selected,
  required,
  multiple = false,
  onChange,
  ...rest
}: AutocompleteProps) => {
  const [requiredError, setRequiredError] = useState<string | undefined>(
    undefined,
  );

  const handleRequired = useCallback(
    (e: any) => {
      if (required) {
        if (selected || e.id || e.target.innerText) {
          setRequiredError(undefined);
        } else {
          setRequiredError(label);
        }
      }
    },
    [required, selected, label],
  );

  return (
    <BaseAutocomplete
      label={label}
      multiple={multiple}
      onChange={(e) => {
        handleRequired(e);
        onChange(e);
      }}
      onBlur={handleRequired}
      error={requiredError}
      selected={selected}
      {...rest}
    />
  );
};

export interface AutocompleteFormProps {
  name: string;
  label: string;
  items: SelectableObject[];
  multiple?: boolean;
  sx?: Record<string, any>;
}

export const AutocompleteForm = ({
  name,
  multiple = false,
  ...rest
}: AutocompleteFormProps) => {
  const context = useContext(FormContext);
  const { value, error } = context.get(name);

  const change = (value: SelectableObject | SelectableObject[] | null) => {
    context.set(name, value);
  };

  return (
    <BaseAutocomplete
      selected={value}
      onChange={change}
      multiple={multiple}
      error={error}
      {...rest}
    />
  );
};

export default AutocompleteForm;
